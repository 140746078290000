
let origin = window.ORIGIN;
//获取场地列表
const getAreaListUrl = `${origin}/gateway/hc-serve/miniapi/reservation/placeList`;
//获取场地详情
const getAreaDetailUrl = `${origin}/gateway/hc-serve/miniapi/reservation/placeDetailInfo`;
//场地类型接口
const getAreaTypeUrl = `${origin}/gateway/hc-serve/miniapi/reservation/placeTypeList`;
//场次列表
const getSessionUrl = `${origin}/gateway/hc-serve/miniapi/reservation/placeScheduleList`;
//预约场次
const getAppointUrl = `${origin}/gateway/hc-serve/miniapi/reservation/addOrder`;
//我的预约
const myAppointUrl = `${origin}/gateway/hc-serve/miniapi/reservation/placeOrderList`;
//预约详情
const myAppointDetailUrl = `${origin}/gateway/hc-serve/miniapi/reservation/placeOrderDetail`;
//取消预约
const cancelAppointUrl = `${origin}/gateway/hc-serve/miniapi/reservation/cancelOrder`;
//我的租赁
const myrentUrl = `${origin}/gateway/hc-space/space/getMsRentApplyList`;
// 常用场馆展示
const showCommonPlaceUrl = `${origin}/gateway/hc-serve/miniapi/reservation/show-common-place`;
//获取区县/街道/社区
const getStreetCommunityUrl = `${origin}/gateway/hc-serve/manageapi/place/get-street-community-list`;

export {
  getAreaListUrl,
  getAreaTypeUrl,
  getAreaDetailUrl,
  getSessionUrl,
  getAppointUrl,
  myAppointUrl,
  myAppointDetailUrl,
  cancelAppointUrl,
  myrentUrl,
  showCommonPlaceUrl,
  getStreetCommunityUrl,
};
