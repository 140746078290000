<template>
  <div class="areaSession">
    <div class="areaSession-t">
      <div class="beforeNow" @click="toBeforeNow">
        <img src="./img/leftArrow.png" alt="" /> 前一天
      </div>
      <div class="now" @click="showCalendar">
        <div class="now-l">{{ date }}</div>
        |
        <div class="now-r"><img src="./img/appoint.png" alt="" /></div>
      </div>
      <div class="afterNow" @click="toAfterNow">
        <img src="./img/rightArrow.png" alt="" /> 后一天
      </div>
    </div>
    <div class="session">
      <div
        v-for="(item, index) in areaDetail"
        :key="index"
        :class="['item', { noAppoint: item.canOrder == 0 }]"
        @click="toClick(item, index)"
      >
        <div class="item-l">{{ item.beginTime + "- " + item.endTime }}</div>
        <div class="item-r">
          <div class="status">
            {{ item.canOrder == 1 ? "可预约" : "不可预约" }}
          </div>
          <div class="select">
            <img v-if="item.isSelect" src="./img/select.png" alt="" />
            <img v-else src="./img/noSelect.png" alt="" />
          </div>
        </div>
      </div>
      <div class="nodata" v-if="isNoData">暂无场次</div>
    </div>
    <div class="areaSession-b" @click="toAppoint">立即预约</div>
    <v-calendar
      :value="isshow"
      @updateValue="updateValue"
      @change="change"
    ></v-calendar>
    <v-dialog
      v-model="isDialog"
      @confirm="postAppoint"
      confirmButtonColor="#007EFF"
    >
      <div class="dialogItem people">
        <div class="item-l">参与人数:</div>
        <div class="item-r">
          <v-stepper v-model="bookingCnt" :min="1" :max="count"></v-stepper>
        </div>
      </div>
      <div class="dialogItem">
        <div class="item-l">预约说明:</div>
        <div class="item-r"><v-input type="textarea" v-model="remark" /></div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getSessionUrl, getAppointUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "areaSession",
  data() {
    return {
      bookingCnt: 1,
      remark: "",
      isDialog: false,
      date: moment(new Date()).format("YYYY-MM-DD"),
      isshow: false,
      placeId: "",
      count: "",
      placeName: "",
      areaDetail: [],
      scheduleIdList: [],
      isNoData: false,
    };
  },
  created() {
    this.placeId = this.$route.query.placeId;
    this.placeName = this.$route.query.placeName;
    this.count = this.$route.query.count;
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    defOrgId() {
      return this.$store.state.defOrgId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  mounted() {
    this.getAreaDetail();
  },
  methods: {
    postAppoint() {
      if (!this.scheduleIdList.length) {
        this.$toast({ message: "请选择场次", duration: 300 });
        return;
      }
      if (!this.remark) {
        this.$toast({ message: "请填写备注", duration: 300 });
        return;
      }
      if (!this.bookingCnt) {
        this.$toast({ message: "请填写人数", duration: 300 });
        return;
      }
      let params = {
        houseId: this.houseId || this.communityId || undefined,
        placeId: this.placeId,
        placeName: this.placeName,
        scheduleIdList: this.scheduleIdList,
        remark: this.remark,
        bookingCnt: this.bookingCnt,
        sourceType: 4,
      };

      this.$axios
        .post(`${getAppointUrl}?userId=${this.userId}`, params)
        .then((res) => {
          this.isDialog = false;
          if (res.code === 200) {
            this.$router.replace({
              name: "appointSuccess",
            });
          } else {
          }
        });
    },
    toAppoint() {
      this.isDialog = true;
    },
    init() {
      this.areaDetail = [];
      this.getAreaDetail();
    },
    toClick(item, index) {
      if (item.canOrder == 0) {
        return;
      }
      item.isSelect = !item.isSelect;
      if (item.isSelect) {
        if (this.scheduleIdList.indexOf(item.id) == -1) {
          this.scheduleIdList.push(item.id);
        }
      } else {
        if (this.scheduleIdList.indexOf(item.id) != -1) {
          let oIndex = this.scheduleIdList.indexOf(item.id);
          this.scheduleIdList.splice(oIndex, 1);
        }
      }
      //   for (var i = 0; i < this.areaDetail.length; i++) {
      //     if (index != i) {
      //       this.areaDetail[i].isSelect = false;
      //     } else {
      //       this.areaDetail[i].isSelect = !this.areaDetail[i].isSelect;
      //     }
      //   }
    },
    toBeforeNow() {
      this.date = moment(this.date).subtract(1, "days").format("YYYY-MM-DD");
      this.init();
    },
    toAfterNow() {
      this.date = moment(this.date).add(1, "days").format("YYYY-MM-DD");
      this.init();
    },
    change(value) {
      console.log(value, 3333);
      this.date = moment(value).format("YYYY-MM-DD");
      this.init();
    },
    updateValue() {
      this.isshow = false;
    },
    showCalendar() {
      console.log(7777);
      this.isshow = true;
    },
    toSubmit() {
      this.$router.push({
        name: "sureSubmit",
        query: { activityId: this.activityId },
      });
    },
    getAreaDetail() {
      let params = {
        placeDate: this.date,
        placeId: this.placeId,
      };

      this.$axios.get(`${getSessionUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          res.data.forEach((element) => {
            element.isSelect = false;
            this.areaDetail.push(element);
          });
          if (this.areaDetail.length == 0) {
            this.isNoData = true;
          } else {
            this.isNoData = false;
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.areaSession {
  overflow: hidden;
  min-height: 100vh;
  .nodata {
    text-align: center;
    font-size: 30px;
    color: #999;
  }
  .areaSession-t {
    display: flex;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    z-index: 2;
    align-items: center;
    justify-content: space-around;
    padding: 24px 32px;
    height: 112px;
    background: #fff;
    .beforeNow {
      position: relative;
      text-align: center;
      line-height: 80px;
      font-size: 32px;
      font-weight: 400;
      color: #646668;
      line-height: 44px;
      flex: 1;
      img {
        width: 24px;
        position: absolute;
        height: 24px;
        top: 0;
        bottom: 0;
        left: 12px;
        margin: auto;
      }
    }
    .now {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
      line-height: 44px;
      width: 320px;
      height: 64px;
      background: #007eff;
      border-radius: 32px;
      .now-l {
        margin-right: 10px;
      }
      .now-r {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .afterNow {
      position: relative;
      flex: 1;
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      color: #646668;
      line-height: 44px;
      img {
        width: 24px;
        position: absolute;
        height: 24px;
        top: 0;
        bottom: 0;
        right: 12px;
        margin: auto;
      }
    }
  }
  .session {
    padding: 144px 32px;

    .item {
      position: relative;
      padding: 32px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        font-size: 32px;
        font-weight: 400;
        color: #323334;
        line-height: 44px;
      }
      .item-r {
        display: flex;
        align-items: center;
        .status {
          font-size: 32px;
          font-weight: 400;
          color: #323334;
          line-height: 44px;
        }
        .select {
          width: 32px;
          height: 32px;
          margin-left: 16px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .noAppoint {
      .item-r {
        opacity: 0.5;
      }
    }
  }
  .areaSession-b {
    width: 100%;
    position: fixed;
    text-align: center;
    color: #fff;
    font-size: 32px;
    line-height: 96px;
    height: 96px;
    background: #007eff;
    bottom: 0;
    z-index: 2;
  }
  .dialogItem {
    .item-l {
      padding: 0 40px;
    }
  }
  .people {
    .item-r {
      padding: 20px 40px;
    }
  }
}
</style>
<style lang="less">
.areaSession {
  .van-field__body {
    border-radius: 8px;
    border: 1px solid #f0f0f0;
  }
  .van-field__control {
    padding: 10px 20px;
    box-sizing: border-box;
  }
  .van-dialog__content {
    padding-top: 80px;
  }
}
</style>
